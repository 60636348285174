import React from 'react';
import cx from 'classnames';
import MuiCard from '@material-ui/core/Card';
import {CARD} from "../../theme/core";


const Card = ({className, contained, actionable, spaceGrey, darkBlue, ...props}) => (
    <MuiCard
        className={cx(
            CARD.root,
            contained && CARD.contained,
            actionable && CARD.actionable,
            spaceGrey && CARD.spaceGrey,
            darkBlue && CARD.darkBlue,
        )}
        {...props}
    />
);


export default Card;