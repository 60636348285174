import React, { useEffect, useState } from "react";
import ShowMore from "@tedconf/react-show-more";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import atoms from "../atoms";
import { collection, getDocs } from "firebase/firestore";

import { db } from "../../config/firebase-config";

const { Avatar, Typography } = atoms;

const Interets = () => {
  const [interets, setInterets] = useState([]);
  const [loading, setLoading] = useState(true);
  const interetsCollectionRef = collection(db, "interets");

  useEffect(() => {
    const getInterets = async () => {
      try {
        const data = await getDocs(interetsCollectionRef);
        setInterets(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getInterets();
  }, []);

  return (
    <ShowMore items={interets.sort((a, b) => a.index - b.index)} by={3}>
      {({ current, onMore }) => (
        <List
          style={{ borderRadius: "16px" }}
          subheader={
            <ListSubheader style={{ borderRadius: "16px" }}>
              Centres d'intérêt{" "}
              <span role="img" aria-label="Red Heart">
                ❤️
              </span>
            </ListSubheader>
          }
        >
          {current.map(({ logo, title, subtitle }) => (
            <React.Fragment key={title}>
              <ListItem button>
                <ListItemAvatar>
                  <Avatar link src={logo} />
                </ListItemAvatar>
                <ListItemText primary={title} secondary={subtitle} ml="2px" />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
          <ListItem
            button
            disabled={!onMore}
            onClick={() => {
              if (!!onMore) onMore();
            }}
          >
            <ListItemText>
              <Typography link>Voir plus</Typography>
            </ListItemText>
          </ListItem>
        </List>
      )}
    </ShowMore>
  );
};

export default Interets;
