import React from "react";
import ReactGA from "react-ga";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import styled from "@material-ui/styles/styled";
import Header from "../../components/portfolio/Header";
import Interets from "../../components/portfolio/Interets";
import Competences from "../../components/portfolio/Competences";
import theme from "../../theme/portfolio/theme";
import withTheme from "./withTheme";
import atoms from "../../components/atoms/index";

import myInfo from "../../data/personalInfo.json";
import TabsPanel from "../../components/portfolio/TabsPanel";
import Pdf from "../../assets/pdf/JA_LUREL_CV.pdf";

const { Avatar, Typography, Button } = atoms;

const Content = styled("div")({
  maxWidth: 1190,
  padding: theme.spacing(1),
  margin: "auto",
});

const Feed = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "16px",
});

const Cover = styled("div")({
  height: 200,
  backgroundImage: `url(${myInfo.backgroundImage})`,
  backgroundSize: `100% auto`,
  backgroundRepeat: `no-repeat`,
  [theme.breakpoints.down("sm")]: {
    height: 125,
  },
});

const handleClick = (event) => {
  ReactGA.event({
    category: "CV",
    action: 'Clic sur le bouton "Voir le CV"',
  });
};

class Profile extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Header />
        <Content>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Feed>
                <Cover />
                <Box p={4} pb={0} mb={1}>
                  <Box
                    css={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      textAlign: "right",
                    }}
                  >
                    <Hidden smUp>
                      <Avatar
                        style={{ marginTop: "-15%" }}
                        medium
                        bordered
                        src={myInfo.image}
                      />
                      <Box style={{ marginTop: "-7%" }}>
                        <Typography inline indentedLarge>
                          <Button
                            large
                            color="primary"
                            variant="contained"
                            href={Pdf}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleClick}
                          >
                            Voir le CV
                          </Button>
                        </Typography>
                      </Box>
                    </Hidden>
                    <Hidden xsDown>
                      <Avatar
                        style={{ marginTop: "-11%" }}
                        ultraLarge
                        bordered
                        src={myInfo.image}
                      />
                      <Box>
                        <Typography inline indentedLarge>
                          <Button
                            large
                            color="primary"
                            variant="outlined"
                            href={Pdf}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleClick}
                          >
                            Voir le CV
                          </Button>
                        </Typography>
                      </Box>
                    </Hidden>
                  </Box>
                  <Box>
                    <Typography primary>
                      {myInfo.prenom} {myInfo.nom}
                    </Typography>
                    <Typography light gutterBottom>
                      {myInfo.pseudo}
                    </Typography>
                    <Typography gutterBottom>{myInfo.poste}</Typography>
                    <Box>
                      <Typography bold inline>
                        <FontAwesomeIcon icon="map-marked-alt" size="xs" />
                      </Typography>
                      <Typography light inline indented>
                        {myInfo.ville}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <TabsPanel />
                <Divider />
              </Feed>
              {/* <Box mt="10px">
								<AccordingWhom />
							</Box> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <Box mb="10px">
                <Competences />
              </Box>
              <Interets />
            </Grid>
          </Grid>
        </Content>
      </React.Fragment>
    );
  }
}

export default withTheme(theme)(Profile);
