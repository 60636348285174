import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import atoms from "../atoms";
import Divider from "@material-ui/core/Divider";

import { faComment, faHeart } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles({
  root: {
    padding: "1rem 10px",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

const { Avatar, IconButton, Typography } = atoms;

const Formation = ({formations}) => {
  const classes = useStyles();

  return (
    <List>
      {formations
        ?.sort((a, b) => b.index - a.index)
        .map(({ dateDebut, dateFin, titre, etablissement, lieu, logo }) => (
          <React.Fragment key={dateDebut}>
            <ListItem button className={classes.root}>
              <Grid container spacing={5} wrap="nowrap">
                <Grid item>
                  <Hidden smDown>
                    <Avatar medium src={logo} />
                  </Hidden>
                  <Hidden smUp>
                    <Avatar small src={logo} />
                  </Hidden>
                </Grid>
                <Grid item>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography bold inline>
                        {titre}
                      </Typography>
                      {" · "}
                      <Typography light inline>
                        {etablissement}
                      </Typography>{" "}
                      <Typography></Typography>{" "}
                      <Typography inline>
                        {dateDebut} - {dateFin}
                      </Typography>
                      {" · "}
                      <Typography light inline>
                        {lieu}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box ml="-12px" display="inline-flex" alignItems="center">
                        <IconButton>
                          <FontAwesomeIcon icon={faComment} size="xs" />
                        </IconButton>
                        <Typography light inline>
                          0
                        </Typography>
                      </Box>
                      <Box ml="32px" display="inline-flex" alignItems="center">
                        <IconButton>
                          <FontAwesomeIcon
                            icon="retweet"
                            size="xs"
                            color="#17bf63"
                          />
                        </IconButton>
                        <Typography light inline success>
                          1
                        </Typography>
                      </Box>
                      <Box ml="32px" display="inline-flex" alignItems="center">
                        <IconButton>
                          <FontAwesomeIcon
                            icon={faHeart}
                            size="xs"
                            color="#e0245e"
                          />
                        </IconButton>
                        <Typography light inline danger>
                          1
                        </Typography>
                      </Box>
                      {/* <Box ml="32px" display="inline-flex" alignItems="center">
                        <IconButton>
                          <FontAwesomeIcon
                            icon="envelope"
                            size="xs"
                            color="red"
                          />
                        </IconButton>
                      </Box> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
    </List>
  );
};

export default Formation;
