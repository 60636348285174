import React from "react";
import ReactGA from "react-ga";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import atoms from "../atoms";
import molecules from "../molecules";

import { faHome } from "@fortawesome/free-solid-svg-icons";

const { AppBar, Avatar, Toolbar } = atoms;
const { Tabs, Tab, ListItem } = molecules;

const handleGithubClick = (event) => {
  ReactGA.event({
    category: "Github",
    action: "Clic sur le bouton Github",
  });
};

const handleLinkedInClick = (event) => {
  ReactGA.event({
    category: "LinkedIn",
    action: "Clic sur le bouton LinkedIn",
  });
};

function Header() {
  return (
    <AppBar position="sticky" elevation={1}>
      <Toolbar>
        <Grid container alignItems="center" spacing={5}>
          <Grid item sm={8} xs={12}>
            <Tabs value={0} variant="fullWidth">
              <Tab
                onlyIcon
                icon={
                  <Badge color="primary" variant="dot">
                    <FontAwesomeIcon icon={faHome} size="lg" />
                  </Badge>
                }
                aria-label="Accueil"
              />
              <Tab
                onlyIcon
                icon={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/jlurel"
                  >
                    <FontAwesomeIcon
                      icon={["fab", "github-square"]}
                      size="2x"
                    />
                  </a>
                }
                aria-label="Github"
                onClick={handleGithubClick}
              />
              <Tab
                icon={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/ja-lurel/"
                  >
                    <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" />
                  </a>
                }
                aria-label="LinkedIn"
                onClick={handleLinkedInClick}
              />
            </Tabs>
          </Grid>
          <Hidden xsDown>
            <Grid item sm></Grid>
          </Hidden>
          <Hidden xsDown>
            <Grid item sm="auto">
              <ListItem>
                <ListItemAvatar>
                  <Avatar src="images/default-user.png" />
                </ListItemAvatar>
                <ListItemText primary="Bienvenue !" />
              </ListItem>
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
