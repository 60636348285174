import React, { useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faBirthdayCake,
  faEnvelope,
  faMapMarkedAlt,
  faMobileAlt,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";

import Profile from "./pages/portfolio/Profile";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

library.add(
  fab,
  faBirthdayCake,
  faEnvelope,
  faMapMarkedAlt,
  faMobileAlt,
  faRetweet
);

const App = () => {
  useEffect(() => {
    const authenticate = () => {
      return new Promise((resolve) => setTimeout(resolve, 1500));
    };

    authenticate().then(() => {
      const element = document.getElementById("ipl-progress-indicator");
      if (element) {
        // fade out
        element.classList.add("available");
        setTimeout(() => {
          // remove from DOM
          element.outerHTML = "";
        }, 1500);
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Profile />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
