import React, { useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import atoms from "../atoms";
import ShowMore from "@tedconf/react-show-more";
import { collection, getDocs } from "firebase/firestore";

import { db } from "../../config/firebase-config";

const { Typography, Icon } = atoms;

const Competences = () => {
  const [loading, setLoading] = useState(true);
  const [competences, setCompetences] = useState([]);

  const competencesCollectionRef = collection(db, "competences");

  useEffect(() => {
    const getCompetences = async () => {
      try {
        const data = await getDocs(competencesCollectionRef);
        setCompetences(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getCompetences();
  }, []);

  return (
    <ShowMore items={competences.sort((a, b) => a.index - b.index)} by={3}>
      {({ current, onMore }) => (
        <List
          style={{ borderRadius: "16px" }}
          subheader={
            <ListSubheader style={{ borderRadius: "16px" }}>
              Compétences
              <span role="img" aria-label="Ampoule"></span>
            </ListSubheader>
          }
        >
          {current.map(({ primary, secondary, tertiary }) => (
            <React.Fragment key={primary}>
              <ListItem button>
                <ListItemText>
                  <Typography primary>{primary}</Typography>
                  <Typography secondary light={!tertiary}>
                    {secondary}
                  </Typography>
                  {tertiary && (
                    <Typography tertiary light>
                      <Icon>call_made</Icon> {tertiary}
                    </Typography>
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
          <ListItem
            button
            disabled={!onMore}
            onClick={() => {
              if (!!onMore) onMore();
            }}
          >
            <ListItemText>
              <Typography link>Voir plus</Typography>
            </ListItemText>
          </ListItem>
        </List>
      )}
    </ShowMore>
  );
};

export default Competences;
