import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import atoms from "../atoms";
import Divider from "@material-ui/core/Divider";

import { faComment, faHeart } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles({
  root: {
    padding: "1rem 10px",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

const { Avatar, IconButton, Typography } = atoms;

const Experiences = ({ experiences }) => {
  const classes = useStyles();

  return useMemo(
    () => (
      <List>
        {experiences
          ?.sort((a, b) => {
            return b.index - a.index;
          })
          .map(
            ({
              index,
              dateDebut,
              dateFin,
              poste,
              employeur,
              lieu,
              description,
              lien,
              logo,
            }) => (
              <React.Fragment key={index}>
                <ListItem button className={classes.root}>
                  <Box mb="5px">
                    <Grid container spacing={2}>
                      <Grid item>
                        <Box
                          display="flex"
                          height="100%"
                          justifyContent="flex-end"
                          alignItems="center"
                          minWidth={49}
                        >
                          <FontAwesomeIcon
                            icon="retweet"
                            size="sm"
                            color="#657786"
                          />
                          <Typography light indented>
                            Vous avez retweeté
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  </Box>
                  <Grid container spacing={5} wrap="nowrap">
                    <Grid item>
                      <Hidden smDown>
                        <Avatar medium src={logo} />
                      </Hidden>
                      <Hidden smUp>
                        <Avatar small src={logo} />
                      </Hidden>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography bold inline>
                            {poste}
                          </Typography>
                          {" ∙ "}
                          <Typography light inline>
                            {employeur}
                          </Typography>{" "}
                          <Typography></Typography>{" "}
                          <Typography inline>
                            {dateDebut} - {dateFin}
                          </Typography>
                          {" ∙ "}
                          <Typography light inline>
                            {lieu}
                          </Typography>{" "}
                          <ul>
                            {description?.map((desc, index) => {
                              return <li key={index}>{desc}</li>;
                            })}
                          </ul>
                          <Typography light>
                            {" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={lien}
                            >
                              {lien}
                            </a>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            ml="-12px"
                            display="inline-flex"
                            alignItems="center"
                          >
                            <IconButton>
                              <FontAwesomeIcon icon={faComment} size="xs" />
                            </IconButton>
                            <Typography light inline>
                              0
                            </Typography>
                          </Box>
                          <Box
                            ml="32px"
                            display="inline-flex"
                            alignItems="center"
                          >
                            <IconButton success>
                              <FontAwesomeIcon
                                icon="retweet"
                                size="xs"
                                color="#17bf63"
                              />
                            </IconButton>
                            <Typography light inline success>
                              1
                            </Typography>
                          </Box>
                          <Box
                            ml="32px"
                            display="inline-flex"
                            alignItems="center"
                          >
                            <IconButton danger>
                              <FontAwesomeIcon
                                icon={faHeart}
                                size="xs"
                                color="#e0245e"
                              />
                            </IconButton>
                            <Typography light inline danger>
                              1
                            </Typography>
                          </Box>
                          {/* <Box ml="32px" display="inline-flex" alignItems="center">
                        <IconButton>
                          <Icon light text>
                            email
                          </Icon>
                        </IconButton>
                      </Box> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </React.Fragment>
            )
          )}
      </List>
    ),
    [experiences]
  );
};

export default Experiences;
