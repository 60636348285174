import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import atoms from "../atoms";
import molecules from "../molecules";
import Experiences from "./Experiences";
import Formation from "./Formation";
import Divider from "@material-ui/core/Divider/Divider";
import Box from "@material-ui/core/Box";
import { collection, getDocs } from "firebase/firestore";

import { db } from "../../config/firebase-config";

const { AppBar } = atoms;
const { Tabs, Tab } = molecules;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabsPanel = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [experiences, setExperiences] = useState([]);
  const [formations, setFormations] = useState([]);

  const experiencesCollectionRef = collection(db, "experiences");
  const formationsCollectionRef = collection(db, "formations");

  useEffect(() => {
    const getExperiences = async () => {
      try {
        const data = await getDocs(experiencesCollectionRef);
        setExperiences(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
        console.log(error);
      }
    };

    const getFormations = async () => {
      try {
        const data = await getDocs(formationsCollectionRef);
        setFormations(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
        console.log(error);
      }
    };

    getFormations();

    getExperiences();
  }, []);

  return (
    <div style={{ borderRadius: "16px" }}>
      <AppBar position="static" elevation={0} style={{ borderRadius: "16px" }}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label="Expérience" {...a11yProps(0)} />
          <Tab label="Formation" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <Divider />
      <TabPanel value={value} index={0}>
        <Experiences experiences={experiences} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Formation formations={formations} />
      </TabPanel>
    </div>
  );
};

export default TabsPanel;
